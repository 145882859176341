import React, { Component } from 'react';
import axios from 'axios';


export default class ProductCart extends Component {
    constructor(props) {
    super(props)
    
    this.state = {
       cartqty:1,
       CartProductsJsonData:[],
       cartItemTotalCost:1,
       cartItemCost:1,
       ItemTotalCost:0,
       cartGrandTotal:0,
     }

  }
 
  updatecartitemqty = (e,index) => {
    
    this.state.CartProductsJsonData[index].cartproductqty = e.target.value;
    
       this.setState({cartqty : e.target.value});
       //alert(this.state.cartItemCost);
      //this.setState({cartItemTotalCost : this.state.cartItemCost *  e.target.value })
      let grandTotal = 0;
      for (var i = 0; i < this.state.CartProductsJsonData.length; i++) 
      {
          this.state.ItemTotalCost = this.state.CartProductsJsonData[i].cartproductfinalprice *  this.state.CartProductsJsonData[i].cartproductqty 
            console.log(this.state.ItemTotalCost);
          grandTotal += this.state.ItemTotalCost
      }
          this.setState({ cartGrandTotal : grandTotal });   
        

  }
 
  
  fetchUserAreaProducts = () => {

     //alert(this.state.activePage);
      
    axios.get('https://codes.biglittleideas.com/learnreact/shopcartdata.php', {
      params: {
          page:this.state.activePage,
              }
          }).then(response => {
       //console.log(response.data);
      this.setState({
        CartProductsJsonData: response.data
        });

        let grandTotal = 0;
        for (var i = 0; i < this.state.CartProductsJsonData.length; i++) 
        {
           this.state.ItemTotalCost = this.state.CartProductsJsonData[i].cartproductfinalprice *  this.state.CartProductsJsonData[i].cartproductqty 
            //this.state.CartProductsJsonData[i].cartuserprice = this.state.ItemTotalCost ;
            grandTotal += this.state.ItemTotalCost
        }
          this.setState({ cartGrandTotal : grandTotal }); 

      });

   };

    componentDidMount() 
    {
       this.fetchUserAreaProducts();

    }

render() {
             
    return (  
    <div>
   
    


    <div class="row" className="hdr">  
    <div class="col-sm-12 btn btn-info">  
      All Products 
    </div>  
    </div>
    
    <div class="row col-lg-6 offset-lg-3">
    <div class="col-12">
    
    <table class="table table-striped table-hover  table-sm" >
    <thead>
    <tr>
    <th>Product Name</th>
    <th>Price</th>
    <th>Qty</th>
    <th>Total Price</th>
    </tr>
    </thead>
    <tbody>	
    {this.state.CartProductsJsonData && this.state.CartProductsJsonData.map((cart, index) => {
    return <tr key={index} >
    <td>{cart.cartproductname}</td>
    <td>{cart.cartproductfinalprice}</td>
    <td> 
     <select name="txtCartItemQty"  onChange={(e) => this.updatecartitemqty(e, index)}  >  
 
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
        </select>

        {cart.cartproductqty} </td>
      <td>
      {cart.cartproductfinalprice * cart.cartproductqty}
         
      
      </td>
    </tr>;

      
    })}
    </tbody>
    </table>
     
    Grand Total {this.state.cartGrandTotal}
    
    </div>
    </div> 
    </div>
    )  
    }  
    
}