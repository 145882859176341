import Cart from '../components/Cart';

import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/action'

const mapStateToProps=state=>({
       data:state.cardItems
     //cardData:state
})
const mapDispatchToProps=dispatch=>({
    addToCartHandler:data=>dispatch(addToCart(data))

})
export default connect(mapStateToProps,mapDispatchToProps)(Cart);