import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function Login()
{
    const navigate = useNavigate();
    const [allValues, setAllValues] = useState({
        email: '',
        password: ''
     });
       
     const changeHandler = e => {
        setAllValues({...allValues, [e.target.name]: e.target.value})
     }

      const handleSubmit = event => 
      { 
         event.preventDefault();
          const formData = new FormData(event.target); // reference to form element
           //console.table(formData);      
          const data = {}; // need to convert it before using not with XMLHttpRequest
          
               for (let [key, val] of formData.entries()) 
                {
                  Object.assign(data, { [key]: val })
                }

                axios
                .post("https://codes.biglittleideas.com/learnreact/userlogin.php", data)
                .then(response => 
                {  
                    if (response.data.status == true)
                    {
                      // localStorage.setItem("token", "ashishashishashishashishashishashish" )
                       
                    //    localStorage.setItem('localsessionid', response.data.outputvalarr.userid);
                    //    localStorage.setItem('localsessionname', response.data.outputvalarr.username);

                       sessionStorage.setItem("sessionuserid", response.data.outputvalarr.userid);
                       sessionStorage.setItem("sessionname", response.data.outputvalarr.username);
                       
                        //  this.setState({
                        //     loggedin: true
                        //  })

                         navigate("/signup");
                    }  
                      
                
              })
                .catch(err => console.log(err));
      
      }  


  return(
    <div className='col-lg-6 offset-lg-3'>
      <h2>LOGIN PAGE</h2>
     
      <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-5" >
        <Form.Label className='text-left'>Email address</Form.Label>
        <Form.Control type="email" name="loginemail" placeholder="Enter email"  onChange={changeHandler}/>
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" name="password" placeholder="Password" onChange={changeHandler} />
      </Form.Group>
       
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>

    </div>

  )


}

export default Login;