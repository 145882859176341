import { useState , useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UserHeader from './userheader';
import UserFooter from './userfooter';
//import FontAwesomeIcon from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UserProductDetail()
{
  const params = useParams();
  const {productid} = params;
    //console.log(productid); 

  const [productinfoData, setProductinfo] = useState([]);
  const [productAttrData , setProductAttrOptions] = useState([]);
  const [productVarientOptsData , setProdVarientOptions] = useState([]);
  const [prodVarientResponsData , setProdVarientResponse] = useState([]);

    useEffect(() => {
       
        axios.get('https://ecomm2021.biglittleideas.com/api/ecom.api.php', { 
        params: {
          searchapiproductID:productid,
          action:'getCrossUserProductinfo'
         }
      })
      .then((res) => {
          //console.log(res.data.records[0]);
           setProductinfo(res.data.records[0]);

          
            setProductAttrOptions(res.data.ProdOptionAttr);
           
            //console.table(res.data.ProdOptionAttr);
          
           //console.table(res.data.ProdVarientOptionAttr);
           setProdVarientOptions(res.data.ProdVarientOptionAttr);
   
      })
      .catch((err) => console.log(err));
    }, []);


const productOptRows = '';
 
console.table(productAttrData);



 if (productAttrData)
{
  const productOptRows =  productAttrData.map((attr,index) => {
  
    return (
   <>             
  <div class="col-12"> <strong> <label > {attr.optionname} </label> </strong> <br/><br/> 
       
       <select className='form-control'  >
          
        {attr.optionvaluesarray.map((optval) => {  
              return <option  value={optval.key}>{optval.value}</option>;  
        })}  
 
       </select>
       
       </div>
     
   </>

   );
 });

 }


// const prodVarientOptRows = '';
 
//  console.table(productVarientOptsData);
//  if (productVarientOptsData)
// {
   
const prodVarientOptRows =  productVarientOptsData.map((attr,index) => {
 
   return (
  <>             
     <div class="col-12"> <strong> <label > {attr.optionname} ashish</label> </strong> <br/><br/> 
     
        <select className='form-control' onChange={(e) => changeVarientOptions(e, index)}>
         
         {attr.optionvaluesarray.map((optval) => {  
               return <option  value={optval.key}>{optval.value}</option>;  
          })}  

       </select> 
      
      </div>
    
  </>

   );
 });

// }


function  changeVarientOptions(e,index) 
{
   //alert("hello");
  const attrdata = {}; // need to convert it before using not with XMLHttpRequest
         
   const variantvalids = [];
   let variantproductattributes = [];
  var variantindex ,variantValName;
  // console.table(this.varientoptdatas);


  var sub_array = [];
  var super_array = [];
  for (var i = 1; i <= 3; i++) {
      sub_array.push(i);
      //super_array.push(sub_array.slice(0));
  }

  //console.table(sub_array);

  for (var i = 0; i <  productVarientOptsData.length; i++) 
  {
           //console.log("----" +productVarientOptsData[i].optionid);
         
        //    console.log("<<------>>" +productVarientOptsData[i].optionvaluesarray.map( (opt) => opt.key) );

        
           //index = productVarientOptsData[i].optionvaluesarray.map((o) => o.key).indexOf(variantvalids[i]);

           // ValName = productVarientOptsData[i].optionvaluesarray[index].value ;
             // console.log("name------>" +ValName);

             variantproductattributes.push([e.target.value],productVarientOptsData[i].optionid);
               
                //console.table(variantproductattributes);

             Object.assign(attrdata, { [e.target.value]: productVarientOptsData[i].optionid });

      //   productVarientOptsData[i].optionvaluesarray.map((opt) => 
      //  {  
      //         variantproductattributes.push([ opt.key , productVarientOptsData[i].optionid]);
          
      //         Object.assign(attrdata, { [opt.key]: productVarientOptsData[i].optionid });
      //   })

        //console.table(attrdata);
   }
     
   console.table(variantproductattributes);

     console.table(attrdata);

   axios.get('https://ecomm2021.biglittleideas.com/api/ecom.api.php', {
    params: {
      variantattrarray:JSON.stringify(attrdata),
      searchapiproductID:productid,
      action:'getProdVarientResponseinfo'
    }
  }).then((response) => {
          setProdVarientResponse(response.data[0]);
  })


}







  
  return (

    // <!--================Single Product Area =================-->
   <>

 <UserHeader />

    <div class="product_image_area">
      <div class="container">
        <div class="row s_product_inner">
          
          <div class="col-lg-6">
            
            <div class="s_product_img">
              
              {/* <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  >
                    <img
                      src="img/product/single-product/s-product-s-2.jpg"
                      alt=""
                    />
                  </li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  >
                    <img
                      src="img/product/single-product/s-product-s-3.jpg"
                      alt=""
                    />
                  </li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  >
                    <img
                      src="img/product/single-product/s-product-s-4.jpg"
                      alt=""
                    />
                  </li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="img/product/single-product/s-product-1.jpg"
                      alt="First slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="img/product/single-product/s-product-1.jpg"
                      alt="Second slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="img/product/single-product/s-product-1.jpg"
                      alt="Third slide"
                    />
                  </div>
                </div>
              </div> */}
            
            <img class="card-img" src="img/product/inspired-product/i1.jpg" alt=""/>

            </div>
          
            

          </div>


  <div class="col-lg-5 offset-lg-1">
    <div class="s_product_text">
             
              <h3>{productinfoData.productname}</h3>

         <div class="row"> 
           <div class="col-12">   
              <h2>{productinfoData.productprice}  <i class="fa fa-inr" style = {{fontSize:"36px"}} ></i> </h2>
           </div>
        </div>

   <ul class="list">
      <li>
          <span>Category</span> : {productinfoData.productcatname} 
      </li>
      <li>
         <span>Availibility</span> : In Stock ( {productinfoData.productstockqty}  )
      </li>
      <li>
         <span>Brand</span> : In Stock ( {productinfoData.productbrandname}  )
      </li>
      <li>
         <span>Vendor</span> : In Stock ( {productinfoData.productstorename}  )
      </li>
   </ul>
      <p>
        Mill Oil is an innovative oil filled radiator with the most
        modern technology. If you are looking for something that can
        make your interior look awesome, and at the same time give you
        the pleasant warm feeling during the winter.
      </p>


<div class="row">
     
     
     {/* {productAttrData.map((attr) => {  
          return <div class="col-12"> <strong> <label > {attr.optionname} </label> </strong> <br/><br/> 
          
          <select className='form-control'>
             
           {attr.optionvaluesarray.map((optval) => {  
                 return <option  value={optval.key}>{optval.value}</option>;  
           })}  

          </select>
          
          </div> ; 
                  
      })} */}
 
          {productOptRows.length ? productOptRows :  ''}
           
          {prodVarientOptRows.length ? prodVarientOptRows :  '--------'}
           

 
 </div>
 
              <div class="product_count">
                <label >Quantity:</label>
                <input
                  type="text"
                  name="qty"
                  id="sst"
                  maxLength="12"
                  value="1"
                  title="Quantity:"
                  class="input-text qty"
                />
                <button
                  onclick="var result = document.getElementById('sst'); var sst = result.value; if( !isNaN( sst )) result.value++;return false;"
                  class="increase items-count"
                  type="button"
                >
                  <i class="lnr lnr-chevron-up"></i>
                </button>
                <button
                  onClick="var result = document.getElementById('sst'); var sst = result.value; if( !isNaN( sst ) &amp;&amp; sst > 0 ) result.value--;return false;"
                  class="reduced items-count"
                  type="button"
                >
                  <i class="lnr lnr-chevron-down"></i>
                </button>
              </div>
              <div class="card_area">
                <a class="main_btn" href="#">Add to Cart</a>
                <a class="icon_btn" href="#">
                  <i class="lnr lnr lnr-diamond"></i>
                </a>
                <a class="icon_btn" href="#">
                  <i class="lnr lnr lnr-heart"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!--================End Single Product Area =================--> */}

    {/* <!--================Product Description Area =================--> */}
    <section class="product_description_area">
      <div class="container">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link" 
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              >Description</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              >Specification</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#contact"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >Comments</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              id="review-tab"
              data-toggle="tab"
              href="#review"
              role="tab"
              aria-controls="review"
              aria-selected="false"
              >Reviews</a
            >
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <p>
              {productinfoData.productdescription}
            </p>
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <h5>Width</h5>
                    </td>
                    <td>
                      <h5>128mm</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Height</h5>
                    </td>
                    <td>
                      <h5>508mm</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Depth</h5>
                    </td>
                    <td>
                      <h5>85mm</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Weight</h5>
                    </td>
                    <td>
                      <h5>52gm</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Quality checking</h5>
                    </td>
                    <td>
                      <h5>yes</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Freshness Duration</h5>
                    </td>
                    <td>
                      <h5>03days</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>When packeting</h5>
                    </td>
                    <td>
                      <h5>Without touch of hand</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Each Box contains</h5>
                    </td>
                    <td>
                      <h5>60pcs</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="comment_list">
                  <div class="review_item">
                    <div class="media">
                      <div class="d-flex">
                        <img
                          src="img/product/single-product/review-1.png"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <h4>Blake Ruiz</h4>
                        <h5>12th Feb, 2017 at 05:56 pm</h5>
                        <a class="reply_btn" href="#">Reply</a>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                  </div>
                  <div class="review_item reply">
                    <div class="media">
                      <div class="d-flex">
                        <img
                          src="img/product/single-product/review-2.png"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <h4>Blake Ruiz</h4>
                        <h5>12th Feb, 2017 at 05:56 pm</h5>
                        <a class="reply_btn" href="#">Reply</a>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                  </div>
                  <div class="review_item">
                    <div class="media">
                      <div class="d-flex">
                        <img
                          src="img/product/single-product/review-3.png"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <h4>Blake Ruiz</h4>
                        <h5>12th Feb, 2017 at 05:56 pm</h5>
                        <a class="reply_btn" href="#">Reply</a>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="review_box">
                  <h4>Post a comment</h4>
                  <form
                    class="row contact_form"
                    action="contact_process.php"
                    method="post"
                    id="contactForm"
                    noValidate="novalidate"
                  >
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          placeholder="Your Full name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="number"
                          name="number"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          name="message"
                          id="message"
                          rows="1"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <button
                        type="submit"
                        value="submit"
                        class="btn submit_btn"
                      >
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade show active"
            id="review"
            role="tabpanel"
            aria-labelledby="review-tab"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="row total_rate">
                  <div class="col-6">
                    <div class="box_total">
                      <h5>Overall</h5>
                      <h4>4.0</h4>
                      <h6>(03 Reviews)</h6>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="rating_list">
                      <h3>Based on 3 Reviews</h3>
                      <ul class="list">
                        <li>
                          <a href="#"
                            >5 Star
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i> 01</a
                          >
                        </li>
                        <li>
                          <a href="#"
                            >4 Star
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i> 01</a
                          >
                        </li>
                        <li>
                          <a href="#"
                            >3 Star
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i> 01</a
                          >
                        </li>
                        <li>
                          <a href="#"
                            >2 Star
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i> 01</a
                          >
                        </li>
                        <li>
                          <a href="#"
                            >1 Star
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i> 01</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="review_list">
                  <div class="review_item">
                    <div class="media">
                      <div class="d-flex">
                        <img
                          src="img/product/single-product/review-1.png"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <h4>Blake Ruiz</h4>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                  </div>
                  <div class="review_item">
                    <div class="media">
                      <div class="d-flex">
                        <img
                          src="img/product/single-product/review-2.png"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <h4>Blake Ruiz</h4>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                  </div>
                  <div class="review_item">
                    <div class="media">
                      <div class="d-flex">
                        <img
                          src="img/product/single-product/review-3.png"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <h4>Blake Ruiz</h4>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="review_box">
                  <h4>Add a Review</h4>
                  <p>Your Rating:</p>
                  <ul class="list">
                    <li>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                    </li>
                  </ul>
                  <p>Outstanding</p>
                  <form
                    class="row contact_form"
                    action="contact_process.php"
                    method="post"
                    id="contactForm"
                    noValidate="novalidate"
                  >
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          placeholder="Your Full name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="number"
                          name="number"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          name="message"
                          id="message"
                          rows="1"
                          placeholder="Review"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <button
                        type="submit"
                        value="submit"
                        class="btn submit_btn"
                      >
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <!--================End Product Description Area =================--> */}

  <UserFooter />

  

 </>



)



}

