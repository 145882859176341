import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";


function Header(props)
{
  console.log(props.data);

  return(
    <div>
       <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">React-ECommerce 2023</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto navbar_wrap">
            
          <Link to="/login"> Login </Link>
          <Link to="/signup"> Sign Up </Link>
          
          <Link to="/logout"> Logout </Link>

            <Nav.Link href="">All Products</Nav.Link>
            
            <Nav.Link href="/usermycart">Cart Page</Nav.Link>
           
            <NavDropdown title="Pages" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">About us</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Terms and Condition
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          
          </Nav>
          <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav>

    
          <div>
             <div className="add-to-cart">
    <span className="cart-count"> {props.data} </span>
                <img src="https://static.vecteezy.com/system/resources/thumbnails/000/496/007/small/Ecommerce_998.jpg" />
            </div>
        </div>



        </Navbar.Collapse>
      </Container>
    </Navbar>

    </div>

  )


}

export default Header;