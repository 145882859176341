function Signup()
{
  return(
    <div>
      <h2>SignUp PAGE</h2>

    </div>

  )


}

export default Signup;