import React from "react";


function Cart(props)
{
    console.log(props.data);
    return(
        <div>
           
        <h1>Cart Component</h1>
        <div className="cart-wrapper">
            <div className="img-wrapper item">
                <img src="https://farmart.botble.com/storage/products/8-300x300.jpg" />
            </div>
            <div className="text-wrapper item">
                <span>
                    I-Phone
                </span>
                <span>
                    Price: 10,000.00
                </span>
            </div>
            <div className="btn-wrapper item">
                <button >
                    Add To Cart</button>
            </div>
        </div>

        <div className="cart-wrapper">
            <div className="img-wrapper item">
                <img src="https://farmart.botble.com/storage/products/21-300x300.jpg" />
            </div>
            <div className="text-wrapper item">
                <span>
                   Classical Leather Watch In Black
                </span>
                <span>
                    Price: 1500.00
                </span>
            </div>
            <div className="btn-wrapper item">
                <button 
                onClick={
                    ()=>{props.addToCartHandler({price:1000,name:'i phone 11'})}
                    } >
                    Add To Cart</button>
            </div>
        </div>



    </div>

    );

}

export default Cart;