import { useNavigate } from "react-router-dom";

function Logout()
{
    const navigate = useNavigate();

    sessionStorage.clear();

    navigate("/login");

}

export default Logout;