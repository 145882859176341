//import logo from './logo.svg';
import './App.css';
//import Header from './components/header';

//import UserHeader from './userarea/components/userheader';
import UserShopProducts from './userarea/components/shopallproducts';
import UserProductDetail from './userarea/components/productdetail';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loginform from './components/login';
import Signup from './components/register';
import Logout from './components/logout'; 
import UserCart from './components/cartproducts';
import HeaderContainer from './containers/HeaderContainer';
import HomeContainer from './containers/HomeContainer';


function App() {
  return (
    <div className="App">
       
     <BrowserRouter> 
     
        {/* <UserHeader /> */}

        <UserShopProducts />
       
       {/* <Header /> */}
      
       <Routes>
          <Route path="/login" element={<Loginform />} />
          <Route path="/signup"  element={<Signup />} />
          
          <Route path="/logout"  element={<Logout />} />

          <Route path="/usermycart"  element={<UserCart />} />

          <Route path="/userproductdetail/:productid"  element={<UserProductDetail />} />

        </Routes>

    </BrowserRouter>
      
      {/* <HeaderContainer /> */}

    {/* <HomeContainer />  */}

    </div>
  );
}

export default App;